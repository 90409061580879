const tilePersons = document.querySelectorAll(".tile-person");

tilePersons.forEach((tilePerson) => {
    // Link aus dem href-Attribut des a-Elements mit der Klasse "contact" extrahieren
    const contactLink = tilePerson
        .querySelector(".contact")
        .getAttribute("href");

    // QR-Code erzeugen
    const qr = qrcode(8, "L");
    qr.addData(contactLink);
    qr.make();

    // QR-Code in der placeHolder-Div anzeigen
    const placeHolder = tilePerson.querySelector("#qrcode");
    let svgTag = qr.createSvgTag(6, 0);

    // Aktualisiere den Inhalt des placeHolders
    placeHolder.innerHTML = svgTag;
});
