const tiles = document.querySelectorAll(".tile");
tiles.length > 0 &&
    tiles.forEach((tile) => {
        const e = tile.querySelector(".date");
        if (e) {
            const regex = /\d{1,2}\.\d{1,2}\.\d{4}/;
            const match = e.innerHTML.match(regex);
            if (match) {
                const t = match[0].trim().split("."),
                    n = t[2] + "-" + t[1] + "-" + t[0],
                    a = new Date(n),
                    o = new Date();
                if (a < o) {
                    tile.classList.add("past");
                }
            }
        }
    });
