document.querySelectorAll('.accordion--item').forEach(item => {
    item.querySelector('button').addEventListener('click', () => {

        if(!item.classList.contains('in--backend')) {
            const parent = item.closest('.accordion')
            const otherItems = parent.querySelectorAll('.accordion--item')

            otherItems.forEach(otherItem => {
                if (otherItem !== item) {
                    otherItem.classList.remove('active')
                    // otherItem.querySelector('.container').slideToggle(350, 'swing')
                }
            })

            item.classList.toggle('active')
            // item.querySelector('.container').slideToggle(350, 'swing')
        }
    })
})
