(function () {
    var menuButton = document.querySelector(".mobile-menu");
    var header = document.querySelector("header");
    var hamburgerIcon = document.querySelector(".hamburger-icon");
    var headerMenu = document.querySelector(".nav-list");
    var navPoint = document.querySelectorAll(".nav-list a");

    function removeActiveClasses() {
        if (window.innerWidth > 900) {
            hamburgerIcon.classList.remove("active");
            headerMenu.classList.remove("active");
            header.classList.remove("active");
        }
    }

    menuButton.addEventListener("click", (event) => {
        header.classList.toggle("active");
        hamburgerIcon.classList.toggle("active");
        headerMenu.classList.toggle("active");
    });

    window.addEventListener("click", (event) => {
        if (event.target != headerMenu && event.target != menuButton) {
            removeActiveClasses();
        }
    });

    navPoint.forEach((item) => {
        item.addEventListener("click", (event) => {
            removeActiveClasses();
        });
    });

    window.addEventListener("resize", removeActiveClasses);

    window.addEventListener("scroll", () => {
        if (window.scrollY > 100) {
            hamburgerIcon.classList.remove("active");
            headerMenu.classList.remove("active");
            header.classList.remove("active");
        }
    });
})();
