document.addEventListener("DOMContentLoaded", function () {
    // Überprüfen, ob Elemente mit der Klasse 'clearfix checkbox' vorhanden sind
    const elements = document.getElementsByClassName("clearfix checkbox");
    if (elements.length > 0) {
        for (let i = 0; i < elements.length; i++) {
            // Suchen Sie innerhalb des Elements nach dem Wort "Datenschutzerklärung"
            const element = elements[i];
            if (element.innerHTML.includes("Datenschutzerklärung")) {
                // Erstellen Sie einen neuen Link und fügen Sie ihn nach dem Wort "Datenschutzerklärung" ein
                const link = document.createElement("a");
                link.href = "https://stb-beratung-weimar.de/datenschutz";
                link.target = "_blank";
                link.textContent = "Datenschutzerklärung";
                const innerHTML = element.innerHTML;
                const index = innerHTML.indexOf("Datenschutzerklärung");
                element.innerHTML = [
                    innerHTML.slice(0, index),
                    link.outerHTML,
                    innerHTML.slice(index + "Datenschutzerklärung".length),
                ].join("");
            }
        }
    }
});
