function isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
    );
}

// Führe den Code nur aus, wenn das Gerät kein mobiles Gerät ist
if (!isMobileDevice()) {
    // Finde alle Elemente mit der Klasse "tile-person"
    const tilePersons = document.querySelectorAll(".tile-person");

    // Iteriere über alle "tile-person"-Elemente
    tilePersons.forEach((tilePerson) => {
        // Finde alle Buttons innerhalb des aktuellen "tile-person"-Elements
        const buttons = tilePerson.querySelectorAll("button");
        // Iteriere über alle Buttons
        buttons.forEach((button) => {
            // Füge einen Klick-Event-Listener hinzu
            button.addEventListener("click", function (event) {
                // Verhindere den Standard-Link-Verhalten des Buttons
                if (!isMobileDevice()) {
                    event.preventDefault();
                }

                // Finde das Popup-Element innerhalb des aktuellen "tile-person"-Elements und füge die Klasse "active" hinzu
                const popup = tilePerson.querySelector(".pop-up");
                if (popup) {
                    popup.classList.add("active");

                    // Füge einen Klick-Event-Listener zum Popup-Element hinzu
                    popup.addEventListener("click", function (event) {
                        // Prüfe, ob der Klick außerhalb des qr-code-container-Elements erfolgte
                        if (!event.target.closest(".qr-code-container")) {
                            // Entferne die Klasse "active" vom Popup-Element
                            popup.classList.remove("active");

                            // Setze das overflow-Attribut des body-Elements zurück
                            document.body.style.overflow = "auto";
                        }
                    });

                    // Finde das Schließen-Element innerhalb des Popup-Elements und füge einen Klick-Event-Listener hinzu
                    const close = popup.querySelector(".close");
                    if (close) {
                        close.addEventListener("click", function (event) {
                            // Verhindere den Standard-Link-Verhalten des Schließen-Elements
                            event.preventDefault();

                            // Entferne die Klasse "active" vom Popup-Element
                            popup.classList.remove("active");

                            // Setze das overflow-Attribut des body-Elements zurück
                            document.body.style.overflow = "auto";
                        });
                    }

                    // Setze das overflow-Attribut des body-Elements auf "hidden", um das Scrollen zu verhindern
                    document.body.style.overflow = "hidden";
                }
            });
        });
    });
}
