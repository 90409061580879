// Warten, bis das DOM vollständig geladen ist
document.addEventListener("DOMContentLoaded", function () {
    // Suche nach Formular-Tag auf der Seite
    const form = document.querySelector("form");

    // Wenn ein Formular gefunden wurde
    if (form) {
        // Suche nach dem Element mit den Klassen "clearfix" und "checkbox" innerhalb des Formulars
        const clearfixCheckbox = form.querySelector(".clearfix.checkbox");

        // Wenn das Element gefunden wurde
        if (clearfixCheckbox) {
            // Das erste (und einzige) Checkbox-Element innerhalb von clearfixCheckbox auswählen
            const checkbox = clearfixCheckbox.querySelector(
                'input[type="checkbox"].add-on'
            );

            // Wenn die Checkbox gefunden wurde
            if (checkbox) {
                // Einen Event-Listener hinzufügen, der bei Änderungen der Checkbox ausgelöst wird
                checkbox.addEventListener("change", function () {
                    // Wenn die Checkbox ausgewählt ist, füge die Klasse "filled" zum Elternelement hinzu, andernfalls entferne sie
                    if (this.checked) {
                        clearfixCheckbox.classList.add("filled");
                    } else {
                        clearfixCheckbox.classList.remove("filled");
                    }
                });
            }
        }

        // Suche nach dem clearfix-Element mit der Klasse "error" innerhalb des Formulars
        const clearfixError = form.querySelector(".clearfix.error");

        // Wenn das Element gefunden wurde
        if (clearfixError) {
            // Suche nach den Input-Tags innerhalb des clearfix-Elements
            const inputs = clearfixError.querySelectorAll("input");

            // Füge jedem Input-Tag eine Event-Listener hinzu, um auf Eingaben des Benutzers zu reagieren
            inputs.forEach((input) => {
                input.addEventListener("input", (event) => {
                    // Wenn der Benutzer etwas eingibt, füge der Klasse "filled" hinzu
                    if (event.target.value.trim() !== "") {
                        clearfixError.classList.add("filled");
                    } else {
                        clearfixError.classList.remove("filled");
                    }
                });
            });
        }
    }
});
