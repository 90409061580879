document.addEventListener("DOMContentLoaded", function () {
    const tabs = document.querySelector(".tabs");
    if (tabs) {
        const buttons = tabs.querySelectorAll(".tabs button");
        const tabItems = tabs.querySelectorAll(".tabs .tab-item");
        tabItems[0].classList.add("active"); // Aktiviert das erste Tab-Item
        buttons[0].classList.add("active"); // Aktiviert den ersten Button
        buttons.forEach(function (button) {
            button.addEventListener("click", function () {
                const targetTabItem = tabItems[[...buttons].indexOf(button)];
                tabItems.forEach((tabItem) =>
                    tabItem.classList.remove("active")
                );
                targetTabItem.classList.add("active");
                buttons.forEach((button) => button.classList.remove("active"));
                button.classList.add("active"); // Aktiviert den angeklickten Button
            });
            if (button.classList.contains("active")) {
                button.click(); // Klickt den aktiven Button an, um das erste Tab-Item anzuzeigen
            }
        });
    }
});
