// Element mit der ID "snackbar" suchen
var snackbarElement = document.getElementById("snackbar");

// Prüfen, ob das Element existiert
if (snackbarElement) {
    // Klasse "show" hinzufügen
    snackbarElement.classList.add("show");

    // Funktion zum Entfernen der Klasse "show" nach 3 Sekunden
    setTimeout(function () {
        snackbarElement.classList.remove("show");
    }, 3000);
}
